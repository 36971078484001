<template>
	<div class="news-item flex flex-between">
		<div class="title" @click="goDetail(folder.article_id)" :title="folder.title"><span>{{ folder.title }}</span></div>
		<div class="release-time">{{ folder.update_time | formatDate }}</div>
	</div>
</template>

<script>
	import { formatDate } from '@/utils/timeStamp.js'
	export default {
		name: 'NewsInfo',	
		props: ['folder'],
		data() {
			return {
				title:'',
			}
		},
		// 过滤时间
		filters: {
			formatDate(time) {
				time = time * 1000
				let date = new Date(time)
				return formatDate(date, 'yyyy-MM-dd')
			}
		},
		created(){

		},
		methods: {
			goDetail(id){
				let routeUrl = this.$router.resolve({
          name:'NewsDetail',
					query:{id:id}
				});
				window.open(routeUrl.href, '_blank');				
			},
		}
	}
</script>

<style lang="less" scoped>
	.news-item {
		width: 100%;
		padding: 0 18px;
		.title{
			width: calc(100% - 100px);
			height: 28px;
			font-size: 14px;
			color: #666666;
			line-height: 28px;
			text-align: left;
			white-space: nowrap;
			text-overflow: ellipsis;
			cursor: pointer;
			overflow: hidden;
			span{
				color: #666666;
				-webkit-transition: all 0.5s;
				-moz-transition: all 0.5s;
				-ms-transition: all 0.5s;
				-o-transition: all 0.5s;
				transition: all 0.5s;
			}
			span:hover{
				color:#d71a18;
			}
		}
		.title:hover{
			color: #d71a18;			
		}
		.release-time{
			width: 100px;
			height: 28px;
			font-size: 14px;
			color: #999999;
			text-align: right;
			line-height: 28px;
		}
	}
</style>
