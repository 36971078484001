<template>
  <div class="detali_box_img video-media">
    <div class="video-example">
      <video
        :src="video_file"
        width="100%"
        height="100%"
				:poster="cover" 
        preload="auto"
        @timeupdate="videoTimeUpdate"
        @click="controlVideo"
        ref="videoCon"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <!-- 视频中的暂停按钮 -->
    <div class="play-btn" @click="controlVideo" :style="[opcityVal]">
      <img src="@/assets/icons/play.png" alt="" />
    </div>
    <div class="player-footer">
      <div class="control-play">
        <div class="left">
          <!-- 控制条的播放和暂停按钮 -->
          <div class="control-play-btn" @click="controlVideo">
            <span class="el-icon-video-play" v-show="!vcIsPlay"></span>
            <span class="el-icon-video-pause" v-show="vcIsPlay"></span>
          </div>
          <!-- 当前播放时间 -->
          <div class="current-time">{{ vcCurrentTime }}</div>
          <span class="division">/</span>
          <!-- 视频总时长 -->
          <div class="duration">{{ totalTime }}</div>
        </div>
        <div class="center">
          <!-- 播放进度条 -->
          <div class="control-progress common-progress">
            <div>
              <el-slider
                v-model="vcProgress"
                :show-tooltip="false"
                :max="durationProgress"
                input-size="small"
                @input="getNewTime"
              ></el-slider>
            </div>
            <!-- <p class="control-progress-item"></p> -->
          </div>
        </div>
        <div class="right">
          <!-- 倍速控制 -->
          <div class="video-speed-box">
            <el-dropdown placement="bottom" @command="handleCommand">
              <!-- <span class="el-dropdown-link"> -->
              <span class="video-speed-show">{{ speedTime }}</span>
              <!-- </span> -->
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">0.5x</el-dropdown-item>
                <el-dropdown-item command="2">1x</el-dropdown-item>
                <el-dropdown-item command="3">1.5x</el-dropdown-item>
                <el-dropdown-item command="4">2x</el-dropdown-item>
                <el-dropdown-item command="5">3x</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 音量控制 -->
          <div class="control-voice common-progress">
            <span class="voice-icon"><img src="@/assets/icons/voice.png" alt=""></span>
            <div class="voice-slider">
              <el-slider
                v-model="voiceProgress"
                input-size="small"
                @change="getNewVoice"
              ></el-slider>
            </div>
          </div>
          <!-- 全屏播放 -->
          <div class="fullscreen" title="全屏" @click="getFullSceen">
            <span class="el-icon-full-screen"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["playerSrc","cover"],
  data() {
    return {
      vcIsPlay: false, //是否播放
      opcityVal: {
        opacity: "1",
      },
      video_file: "",
      currentTimeVal: 0, // 当前时间
      vcCurrentTime: "00:00:00", //当前时间格式化
      vcProgress: 0, //进度条的绑定时间
      durationProgress: 0, //当前视频的总时长
      speedTime: "1x", //倍速
      voiceProgress: 50, //声音
      totalTime: "00:00:00", //视频总时长
    };
  },
  computed: {
    player() {
      return this.$refs.videoCon.player;
    },
  },
  created() {
    this.video_file = this.playerSrc;
    this.getVideoTime();
  },
  methods: {
    // 播放和暂停视频
    controlVideo() {
      let videoObj = this.$refs.videoCon;
      if (this.vcIsPlay) {
        videoObj.pause();
      } else {
        videoObj.play();
      }
      this.vcIsPlay = !this.vcIsPlay;
      this.opcityVal.opacity = this.opcityVal.opacity == "1" ? "0" : "1";
    },
    // 获取视频总时长
    getVideoTime() {
      setTimeout(() => {
        let durationTime = this.$refs.videoCon.duration; //总时间
        this.durationProgress = durationTime;
        this.totalTime = this.getFormatTotalTime(durationTime);
      }, 500);
    },
    // 获取时间
    videoTimeUpdate() {
      let videoObj = this.$refs.videoCon;
      let currTime = videoObj.currentTime; //当前时间
      this.vcProgress = currTime; //赋值给进度条
      this.vcCurrentTime = this.getFormatVideoTime(currTime);
      //console.log(this.vcCurrentTime); //"00:00:27"
    },
    //格式化时间
    getFormatVideoTime(time) {
      let curtime = time;
      let h = parseInt(curtime / 3600);
      let m = parseInt((curtime % 3600) / 60);
      let s = parseInt(curtime % 60);
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return h + ":" + m + ":" + s;
    },
    getFormatTotalTime(time) {
      let durationTime = time;
      let h = parseInt(durationTime / 3600);
      let m = parseInt((durationTime % 3600) / 60);
      let s = parseInt(durationTime % 60);
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return h + ":" + m + ":" + s;
    },
    //element 的滑动组件有个chang事件
    getNewTime(val) {
      this.$refs.videoCon.currentTime = val;
      // console.log(val);
    },
    // 获取当前播放的速度
    handleCommand(val) {
      let videoObj = this.$refs.videoCon;
      switch (val) {
        case "1":
          videoObj.playbackRate = 0.5;
          this.speedTime = "0.5x";
          break;
        case "2":
          videoObj.playbackRate = 1;
          this.speedTime = "1x";
          break;
        case "3":
          videoObj.playbackRate = 1.5;
          this.speedTime = "1.5x";
          break;
        case "4":
          videoObj.playbackRate = 2;
          this.speedTime = "2x";
          break;
        case "5":
          videoObj.playbackRate = 3;
          this.speedTime = "3x";
          break;
        default:
          videoObj.playbackRate = 1;
          this.speedTime = "1x";
          break;
      }
    },
    // 设置声音
    getNewVoice(val) {
      let newVc = val / 100; //h5规定,volume的值必须再0-1之间,比如0.5就是50%的音量,但是进度条的值为100,因此这里做个除法
      this.$refs.videoCon.volume = newVc; //赋值
    },
    // 全屏播放
    getFullSceen() {
      let videoObj = this.$refs.videoCon;
      videoObj.webkitRequestFullScreen();
    },
  },
  mounted(){
    this.getNewVoice(50)
  }
};
</script>
<style lang='less' scoped>
.video-media {
  position: relative;
  height: 480px;
  overflow: hidden;
  .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    margin-top: -24px;
    margin-left: -24px;
    background: transparent;
    color: #d71a18;
    z-index: 99;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
    }
  }
  .player-footer {
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      rgba(0, 0, 0, 0.5)
    );
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    z-index: 7;
    height: 48px;
    padding: 0 10px;
    .control-play {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: inherit;
      .left {
        display: flex;
        height: inherit;
        .control-play-btn {
          display: flex;
          align-items: center;
          width: 36px;
          height: 48px;
          font-size: 28px;
          line-height: 48px;
          cursor: pointer;
          .el-icon-video-play:before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background-image: url("~@/assets/icons/icon-play2.png");
            background-size: cover;
          }
          .el-icon-video-pause:before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background-image: url("~@/assets/icons/icon-pause.png");
            background-size: cover;
          }
        }
        .current-time {
          font-size: 14px;
          height: 48 px;
          line-height: 48px;
          font-weight: normal;
        }
        .division {
          display: block;
          font-size: 14px;
          height: 48 px;
          line-height: 48px;
          margin: 0 6px;
          color: white;
        }
        .duration {
          font-size: 14px;
          height: 48px;
          line-height: 48px;
          font-weight: normal;
        }
      }
      .center {
        display: flex;
        align-items: center;
        flex: 1;
        height: 25px;
        width: calc(100% - 30px);
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 48px;
        .common-progress {
          position: relative;
          width: 100%;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
      .right {
        display: flex;
        flex: 1;
        height: inherit;
        justify-content: flex-end;
        align-items: center;
        width: 200px;
        /deep/.video-speed-box {
          width: 48px;
          height: 48px;
          line-height: 48px;
          font-size: 14px;
          color: #fff;
          display: none;
          .el-dropdown {
            color: #fff;
          }
        }
        /deep/.control-voice {
          width: 60px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .voice-icon{
            width: 16px;
            img{
              display: block;
              width: 100%;
            }
          }
          .voice-slider {
            width: 40px;
            .el-slider__button-wrapper{
              top: -16px;
            }
            .el-slider__button{
              border: none;
              width: 5px;
              border-radius: 0;
            }
            .el-slider__runway {
              height: 4px;
            }
            .el-slider__bar {
              height: 4px;
            }
          }
        }
        .fullscreen {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 48px;
        }
      }
    }
  }
  &:hover .player-footer {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
</style>	
