 <template>
<!-- ip规划首页 -->
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<el-carousel :interval="5000" arrow="hover" indicator-position="none">
				<el-carousel-item :data="bannerList" v-for="item in bannerList" :key="item.id">
					<img :src="item.image" class="image" @click="openBanner(item.url)">
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 四大课程选择 -->
		<div class="course-choose">
			<div :data="courseImage" v-for="courseItem in courseImage" :key="courseItem.id">
				<div class="course-items" @click="openBlank('CourseIndex',courseItem.ip_type_id)">
					<img :src="courseItem.thumb">
				</div>
			</div>
		</div>
		<!-- 资讯动态 -->
		<div class="course-dynamic">
			<div class="content flex flex-end">
				<div class="title-more" @click="openBlank('NewsList')">更多</div>
			</div>
			<div class="content flex flex-between">
				<div class="m-video">
					<video-player :playerSrc="playerSrc" :cover="cover" v-if="playerSrc"></video-player>
					<el-empty description="暂无视频数据" v-if="!playerSrc"></el-empty>
				</div>
				<div class="m-dynamic">				
					<div class="news-title" @click="openBlank('NewsList')" :title="latestNews.title">
						{{latestNews.title}}
					</div>
					<div class="news-main">
						<div class="thumbnail" @click="openBlank('NewsDetail',latestNews.article_id)">
							<el-image :src="latestNews.thumb" fit="cover"></el-image>
							<p>{{latestNews.content}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 资讯通知 -->
		<div class="information-notice">
			<div class="content flex flex-between">
				<div class="m-items exam-arrangement">
					<div class="titleBox flex flex-between">
						<div class="ft-title flex flex-start">
							<img src="@/assets/icons/icon-test-white.png">
							<h3>考试安排</h3>
						</div>
						<div class="rt-more" @click="openBlank('ExamArrangement')">更多</div>
					</div>
					<div class="news-main">
						<div v-for="(folder,index) in folders" :key="'news'+index">
							<news-list :folder="folder"></news-list>
						</div>
					</div>
				</div>
				<div class="m-items project-policy">
					<div class="titleBox flex flex-between">
						<div class="ft-title flex flex-start">
							<img src="@/assets/icons/icon-project-white.png">
							<h3>项目政策</h3>
						</div>
						<div class="rt-more" @click="openBlank('ProjectPolicy')">更多</div>
					</div>
					<div class="news-main">
						<div v-for="(folder,index) in foldersTwo" :key="'news'+index">
							<news-list :folder="folder"></news-list>
						</div>
					</div>
				</div>
				<div class="m-items problem-solving">
					<div class="titleBox flex flex-between">
						<div class="ft-title flex flex-start">
							<img src="@/assets/icons/icon-question-white.png">
							<h3>问题解答</h3>
						</div>
						<div class="rt-more" @click="openBlank('ProblemSolving')">更多</div>
					</div>
					<div class="news-main">
						<div v-for="(folder,index) in foldersThree" :key="'news'+index">
							<news-list :folder="folder"></news-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import NewsList from './components/newsList'
	import VideoPlayer from '@/components/player/play'
	
	export default {
		components: {	
			"news-list":NewsList,
			"video-player": VideoPlayer
		},
		data() {
			return {
				//banner
				bannerList: [],
				//课程选择
				courseImage: [],
				//通知-考试安排
				folders: [],
				//通知-项目政策
				foldersTwo: [],
				//通知-问题解答
				foldersThree: [],
				latestNews:{},
				videoInfo:{},
				playerSrc: '',
				cover: '',
			}
		},
		created() {
			this.getBannerList();
			this.getCourseImage();
			this.getFolders();
			this.getInfo();
		},
		methods: {
			
			//banner
			getBannerList() {					
				this.$http.get('/api/web/index/getBannerList')
				.then((res) => { 
					this.bannerList = res.data.bannerList
				})
			},
			//课程
			getCourseImage() {		
				this.$http.get('/api/web/index/getIpTypeList')
				.then((res) => {
					this.courseImage = res.data.ipTypeList
				})			
			},
			//通知
			getFolders() {
				this.$http
				.get('/api/web/index/getNoticeList')
				.then((res) => {
						this.folders = res.data.examSchedule,
						this.foldersTwo = res.data.projectPolicy,
						this.foldersThree = res.data.problemSolving
					})		
			},
			goNews(type) {
				this.$router.push({
					name: 'News',
					query: {
						type
					},
				});
			},
			openBlank(name,id){				
				let routeUrl = this.$router.resolve({
          name,
					query:{id},	
				});
				window.open(routeUrl.href, '_blank');
			},
			openBanner(url){
				if(!!url){
					window.open(url, '_blank');	
				}	else{
					return false
				}			
			},	
			getInfo() {
				this.$http
				.get('/api/web/index/getInfo')
				.then(res => {
					this.latestNews=res.data.latestNews;
					this.videoInfo=res.data.videoInfo;
					this.playerSrc = res.data.videoInfo.video; //视频地址
					this.cover=res.data.videoInfo.thumb;
					console.log(this.cover)
				})
				.catch(error => {
				})			
			}
		},
		metaInfo: {
			title: 'IP规划师培训官网', 
			meta: [
				{
					name: 'keywords',
					content: 'IPUOL,IP,IP规划师,IP规划师培训,IP规划师培训官网'
				},
				{
					name: 'description',
					content: 'IP规划师培训官网首页,IP规划师培训官网,IP规划师培训'
				}
			]
    }
	};
</script>

<style lang='less' scoped>
	.banner {
		width: var(--container-width);
		min-height: 410px;
		margin: 30px auto;
		overflow: hidden;
		border-radius: 10px;
		img {
			display: block;
			width: 100%;
		}
		/deep/.el-carousel{
			height: 410px;
			.el-carousel__arrow {
				background-color: rgba(215, 26, 24, .2);
			}
			.el-carousel__arrow:hover {
				background-color: rgba(215, 26, 24, .5);
			}
		}
	}
	.course-choose {
		display: flex;
		justify-content: space-between;
		width: var(--container-width);
		margin: 0 auto 30px;
		cursor: pointer;
		.course-items {
			width: 300px;
			border-radius: 6px;
			overflow: hidden;
			img {
				display: block;
				width: 100%;
			}
		}
	}

	.course-dynamic {
		width: 100%;
		padding: 30px 0;
		background: #f5f5f5;
		.content {
			width: var(--container-width);
			margin: 0 auto;
			.title-more {
				font-size: 16px;
				font-weight: 400;
				color: #999999;
				margin-bottom: 20px;
				cursor: pointer;
				border-left: solid 1px #b9b9b9;
    		padding-left: 10px;				
			}
			.m-video {
				position: inherit;
				width: 855px;
				height: 480px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
				overflow: hidden;
				background-color: #ebebeb;
				img {
					display: block;
					width: 100%;
				}
				/deep/.video-js .vjs-big-play-button {
					width: 2.5em;
					height: 2.5em !important;
					font-size: 2.5em;
					line-height: 2.5em !important;
					border-radius: 100%;
				}
				/deep/.vjs-custom-skin:hover .video-js .vjs-big-play-button {
					background-color: rgba(232, 12, 10, .5) !important;
				}
			}
			.m-dynamic {
				width: 425px;
				background: white;
				border-radius: 6px;
				overflow: hidden;
				cursor: pointer;
				.news-title {
					width: 100%;
					height: 70px;
					line-height: 70px;
					font-size: 22px;
					color: #333333;
					text-align: left;
					background: white;
					white-space: normal;
					text-overflow: ellipsis;
					overflow: hidden;
					padding: 0 20px;
					border-bottom: solid 2px #f5f5f5;
					border-radius: 6px;
					cursor: pointer;
					
				}
				.news-main {
					width: 100%;
					padding: 20px;
					.thumbnail {
						width: 100%;
						.el-image {
							width: 386px;
							height: 152px;
						}
					}
					p {
						font-size: 14px;
						line-height: 24px;
						text-align: left;
						color: #333333;
					}
				}
			}
		}
	}
	.information-notice {
		width: 100%;
		height: auto;
		padding: 30px;
		background-color: #ffffff;
		.content {
			width: var(--container-width);
			margin: 0 auto;
			.m-items {
				width: 425px;
				min-height: 250px;
				border-radius: 6px;
				overflow: hidden;
				border: solid 1px #dadada;
				.titleBox {
					width: 100%;
					height: 68px;
					padding: 18px;
					.ft-title {
						height: 32px;
						img {
							display: block;
							width: 32px;
							height: 32px;
							margin-right: 10px;
						}
						h3 {
							height: 32px;
							font-size: 18px;
							font-weight: bold;
							color: #ffffff;
							line-height: 32px;
							margin: 0;
						}
					}
					.rt-more {
						height: 32px;
						font-size: 14px;
						color: #f5f5f5;
						line-height: 32px;
						cursor: pointer;
					}
				}
				.news-main {
					width: 100%;
					padding: 10px 0;
				}
			}
			.exam-arrangement {
				background-color: #ffffff;

				.titleBox {
					background-color: #ffd000;
				}
			}
			.project-policy {
				background-color: #ffffff;

				.titleBox {
					background-color: #ff6c00;
				}
			}
			.problem-solving {
				background-color: #ffffff;
				.titleBox {
					background-color: #e80c0a;
				}
			}
		}
	}
</style>
